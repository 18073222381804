<template>
  <div class="footercommon">
    <b-row>
      <b-col v-for="col in columns" :key="col" :cols="scss.vars['mobile-breakpoint-down']" class="footercommon-col">
        <slot :name="col"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
const EXCLUDE_SCOPED_SLOTS = []

export default {
  name: 'FooterCommon',
  data () {
    return {
      scss: this.$root.scss
    }
  },
  computed: {
    columns () {
      return Object.keys(this.$scopedSlots)
        .filter(sKey => !EXCLUDE_SCOPED_SLOTS.includes(sKey))
    }
  }
}
</script>

<style lang="scss">
$footercommon-padding-y: $component-padding-y * 1.25 !default;
$footercommon-padding-x: $component-padding-x !default;
$footercommon-bg: $white !default;
$footercommon-border-radius: $leaf-border-radius;
$footercommon-font-size: $font-size-sm;

$footercommon-footer-title-gap: $spacer * 0.75 !default;
$footercommon-footer-title-font-family: $headings-font-family !default;
$footercommon-footer-title-font-weight: $headings-font-weight !default;
$footercommon-footer-title-font-size: $h3-font-size !default;
$footercommon-footer-title-line-height: $headings-line-height !default;
$footercommon-footer-title-color: $headings-color !default;

$footercommon-link-padding-y: $spacer * 0.15 !default;
$footercommon-link-padding-x: 0 !default;
$footercommon-link-font-weight: inherit !default;
$footercommon-link-font-size: $footercommon-font-size !default;

$footercommon-link-title-gap: $spacer * 0.5 !default;
$footercommon-link-title-font-style: italic !default;

$footercommon-certificate-padding-y: $spacer * 0.15 !default;
$footercommon-certificate-padding-x: $footercommon-certificate-padding-y !default;
$footercommon-certificate-img-size: 40px !default;

$footercommon-socialmedia-padding-y: $footercommon-certificate-padding-y !default;
$footercommon-socialmedia-padding-x: $footercommon-certificate-padding-x !default;
$footercommon-socialmedia-icon-size: $font-size-base * 2 !default;
$footercommon-socialmedia-icon-bg: $primary !default;
$footercommon-socialmedia-icon-font-size: 135% !default;
$footercommon-socialmedia-icon-color: $white !default;

$footercommon-socialmedia-icon-hover-bg: darken($primary, 5%) !default;

$footercommon-mobile-breakpoint: $mobile-breakpoint !default;

$footercommon-mobile-col-gap: $grid-gutter-width * 0.5 !default;

.footercommon {
  padding: $footercommon-padding-y $footercommon-padding-x;
  background-color: $footercommon-bg;
  font-size: $footercommon-font-size;
  @include make-leaf($footercommon-border-radius, 'top');

  .footer-title {
    margin-bottom: $footercommon-footer-title-gap;
    font-family: $footercommon-footer-title-font-family;
    font-weight: $footercommon-footer-title-font-weight;
    font-size: $footercommon-footer-title-font-size;
    line-height: $footercommon-footer-title-line-height;
    color: $footercommon-footer-title-color;

    &:not(:first-child) {
      margin-top: $footercommon-footer-title-gap * 1.4;
    }
  }

  .vcard {
    a {
      text-decoration: none;
    }
  }

  a {
    display: inline-block;
    padding: $footercommon-link-padding-y $footercommon-link-padding-x;
    font-weight: $footercommon-link-font-weight;
    font-size: $footercommon-link-font-size;
  }

  ul {
    @include list-unstyled();
    margin: 0;

    li {
      .link-title {
        margin-top: $footercommon-link-title-gap;
        padding: $footercommon-link-padding-y $footercommon-link-padding-x;
        font-style: $footercommon-link-title-font-style;
      }

      a {
        text-decoration: none;
      }

      &:first-child {
        .link-title {
          margin: 0;
        }
      }
    }

    &.certificates {
      display: flex;
      flex-wrap: wrap;
      margin: ($footercommon-certificate-padding-y * -1) ($footercommon-certificate-padding-x * -1);

      > li {
        padding: $footercommon-certificate-padding-y $footercommon-certificate-padding-x;

        img {
          display: block;
          margin: 0;
          width: 100%;
          width: $footercommon-certificate-img-size;
        }
      }
    }

    &.socialmedia {
      display: flex;
      flex-wrap: wrap;
      margin: ($footercommon-socialmedia-padding-y * -1) ($footercommon-socialmedia-padding-x * -1);

      > li {
        padding: $footercommon-socialmedia-padding-y $footercommon-socialmedia-padding-x;

        > a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: $footercommon-socialmedia-icon-size;
          height: $footercommon-socialmedia-icon-size;
          background-color: $footercommon-socialmedia-icon-bg;
          font-size: $footercommon-socialmedia-icon-font-size;
          color: $footercommon-socialmedia-icon-color;
          border-radius: 50%;

          &:hover {
            background-color: $footercommon-socialmedia-icon-hover-bg;
          }
        }
      }
    }
  }

  @include media-breakpoint-down($footercommon-mobile-breakpoint) {
    .footercommon-col {
      &:not(:first-child) {
        margin-top: $footercommon-mobile-col-gap;
      }
    }
  }
}
</style>
